<template>
  <div class="pt-2">
    <v-textarea
      placeholder="Вставьте URL-адрес или код для встраивания"
      v-model="form.data.content"
      hide-details
    ></v-textarea>
  </div>
</template>

<script>
import isXML from "is-xml";
export default {
  props: {
    form: {
      type: Object,
    },
  },
  methods: {
    validate(data) {
      let url = null;
      if (isXML(data.data.content) && data.data.content.includes("iframe")) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data.data.content, "text/html");
        const iFrame = doc.getElementsByTagName("iframe");
        url = iFrame[0].src;
        // console.log(doc);
      }

      if (data.data.content.trim().substr(0, 4) === "http") {
        url = data.data.content;
        if (url.includes("?v=") && url.includes("youtube")) {
          url = "https://www.youtube.com/embed/" + url.split("?v=")[1];
        }
      }

      if (url) {
        const res = { ...data };
        res.data.content = `<iframe frameborder="0"allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen height="315" width="100%" src="${url}" class="embed-widget__iframe"></iframe>`;
        // console.log(res);

        return res;
      }

      return {
        error: "Неизвестный код для встраивания",
      };
    },
  },
};
</script>

<style lang="scss">
textarea::-webkit-input-placeholder {
  text-align: center;
}
</style>
